import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";
import LanguageSelector from "../components/layoutMenu/languageSelector";
import { IEnigmaReport } from "./enigmaType";
import { IWarnNotices } from "./warnNoticeType";

interface IMonitorLink {
  id: number;
  clientId: string;
  creditExtended: number;
  creditUtilized: number;
  isActive: string;
  isFavorite: boolean;
  organizationId: string;
  client: IDeepClient;
  clientWARNNotices: IWarnNotices[];
}

interface IAssignee {
  userId: string;
  organizationId?: string;
  phoneNumber?: string;
  socialInsuranceNumber?: string;
  address?: string;
  citizenship?: string;
  city?: string;
  state?: string;
}

interface IPerson {
  id: string;
  peopoleDataLabsReportId: number;
  fullName: string;
  firstName: string;
  jobTitle: string;
  workEmail: string;
  mobilePhone: string;
  linkedinUrl: string;
  facebookUrl: string;
  twitterUrl: string;
  activeJobs: IJob[];
}

interface IJob {
  id: string;
  personId: string;
  companyName: string;
  jobTitle: string;
  startDate: string;
}

interface ICreditReport {
  id: number;
  clientId: number;
  dateReportUpdated: string;
  creditRatingCommonValue: string;
  creditRatingCommonDescription: string;
  creditRatingLimit: string;
  creditRatingLimitCurrency: string;
  latestTurnOverValue: number;
  latestTurnOverCurrency: string;
  creditLimit: string;
  creditLimitCurrency: string;
  providerValue: string;
  providerDescription: string;
  latestRatingChangeDate: string;
  dbt: number;
  pastDuePercent: string | null;
  pastDueTotal: number | null;
  averageCreditAmount: number | null;
  severelyPastDuePercent: string | null;
  severelyPastDueTotal: string | null;
  totalTradeExperiences: number | null;
  industryDbt: number | null;
  totalTradesBalance: number | null;
  dbtHistory: IdbtItem[];
  commentaries: IComment[];
  lienFilings: IlienFiling[];
  legalFilings: IlegalFiling[];
  uccFilings: IUccFiling[];
  judgmentFilings: IJudgmentFiling[];
  paidHistoricalTradeInformation: IPaidHistoricalTradeInformation[];
  outstandingHistoricalTradeInformation: IOutstandingHistoricalTradeInformation[];
  tradePaymentInformation: ITradePaymentInformation[];
  currentRatio: number;
  quickRatio: number;
  zScore: number;
  assetsTurnoverRatio: number;
  returnOnEquity: number;
  netProfitMargin: number;
  totalDebtRatio: number;
  totalQuarterlyRevenue: number;
  totalUCCFilings: number | null;
  totalCautionaryUCCFilings: number | null;
  totalJudgmentFilings: number | null;
  totalSuits: number | null;
  totalTaxLienFilings: number | null;
  possibleOfac?: boolean | null;
  createdAt: string;
  updatedAt: string;
}

interface IJudgmentFiling {
  id: number;
  creditReportId: number;
  currency: string;
  filedDate: string;
  plaintiff: string;
  status: string;
  filingType: string;
  actionType: string;
  amount: number;
  filingNumber: string;
  courtName: string;
  courtAddress: string;
  courtTelephone: string;
  createdAt: string;
  updatedAt: string;
}

interface IPaidHistoricalTradeInformation {
  id: number;
  creditReportId: number;
  currency?: string;
  date?: string;
  paidCurrent: number;
  paid1To30: number;
  paid31To60: number;
  paid61To90: number;
  paid91Plus: number;
  createdAt: Date;
  updatedAt: Date;
}

interface IOutstandingHistoricalTradeInformation {
  id: number;
  creditReportId: number;
  currency?: string;
  date?: string;
  range1to30?: number;
  range31to60?: number;
  range61to90?: number;
  range91plus?: number;
  balanceCurrent?: number;
  createdAt: Date;
  updatedAt: Date;
}

interface ITradePaymentInformation {
  id: number;
  creditReportId: number;
  type: "company" | "group";
  currency?: string;
  balance130?: number;
  balance3160?: number;
  balance6190?: number;
  balance91plus?: number;
  balanceCurrent?: number;
  tradeLines?: number;
  invoicesPaid1To30Late?: number;
  invoicesPaid31To60Late?: number;
  invoicesPaid61To90Late?: number;
  invoicesPaid91PlusLate?: number;
  invoicesPaidOnTime?: number;
  tradeLinesPaid?: number;
  invoicesValuePaid1To30Late?: number;
  invoicesValuePaid31To60Late?: number;
  invoicesValuePaid61To90Late?: number;
  invoicesValuePaid91PlusLate?: number;
  invoicesValuePaidOnTime?: number;
}

interface IlienFiling {
  id: number;
  creditReportId: number;
  currency: string;
  filedDate: string;
  filingType: string;
  actionType: string;
  status: string;
  amount: number;
  filingNumber: string;
  jurisdiction: string;
  createdAt: string;
  updatedAt: string;
}

interface IlegalFiling {
  actionType: string;
  amount: number;
  courtName: string;
  courtTelephone: string;
  createdAt: string;
  creditReportId: number;
  currency: string;
  filedDate: string;
  filingNumber: string;
  filingType: string;
  id: number;
  plaintiff: string;
  status: string;
  updatedAt: string;
}

interface IUccFiling {
  id: number;
  creditReportId: number;
  filedDate: string;
  filingType: string;
  filingNumber: string;
  jurisdiction: string;
  filingOffice: string;
  securedPartyName: string;
  securedPartyAddress: string;
  collateral: string;
  debtorName: string;
  debtorAddress: string;
  relatedDocumentNumber: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

interface IdbtItem {
  date: string;
  industryDbt: number;
  dbt: number;
  createdAt: string;
  updatedAt: string;
}
interface IComment {
  id: number;
  creditReportId: number;
  commentaryText: string;
  positiveOrNegative: string;
  createdAt: string;
  updatedAt: string;
}
interface IPeopleDataLabsReport {
  id: number;
  clientId: number;
  employeeSize: string;
  employeeCount: number;
  inferredRevenue: string;
  employeeGrowthRate12MonthsByRole: Record<string, number | null>;
  employeeGrowthRate: number;
  employeeChurnRate: number;
  averageEmployeeTenure: number;
  averageTenureByRole: Record<string, number>;
  averageTenureByLevel: Record<string, number>;
  employeeCountByCountry: Record<string, number>;
  employeeCountByMonth: Record<string, number>;
  employeeCountByRole: Record<string, number>;
  grossAdditionsByMonth: Record<string, number> | null;
  grossDeparturesByMonth: Record<string, number>;
  totalFundRaisingRaised?: number | null;
  latestFundingStage?: string | null;
  lastFundingDate?: string | null;
  numberFundingRounds?: number | null;
  fundingStages?: any | null;
  ownershipStructure?: string | null;
  ticker: string | null;
  people: IPerson[];
  createdAt: string;
  updatedAt: string;
}

interface IClient {
  id: number;
  name: string;
  legalName: string;
  email: string;
  role: string;
  pulseScore: number | null;
  city: string;
  province: string;
  createdAt: string;
  isFavorite: boolean;
  assignees: IAssignee[];
}

interface IDeepClient extends IClient {
  NAICS: string;
  NAICSDescription: string;
  SIC: string;
  SICDescription: string;
  taxId: string;
  address: string;
  facebook: string;
  twitter: string;
  linkedIn: string;
  websites: string[];
  summary: string;
  officeType: string;
  legalType: string;
  companyIndustry: string;
  dbas: string[];
  pulseScores: IPulseScore[];
  affiliatedCompanies: IAffiliatedCompany[];
  companyRegistrationDate: string;
  profitOrNonProfit?: string | null;
  foreignOrDomestic?: string | null;
  SOSCharterNumber?: string | null;
  dbtHistory: IdbtItem[];
  enigmaReport: IEnigmaReport[];
  creditReport: ICreditReport[];
  peopleDataLabsReport: IPeopleDataLabsReport[];
}

interface IPulseScore {
  id: number;
  clientId: number;
  creditPulse: number;
  trustPulse: number;
  momentumPulse: number;
  financialPulse: number;
  createdAt: string;
  updatedAt: string;
}

interface IAffiliatedCompany {
  id: number;
  clientId: number;
  relationship: string;
  country: string;
  creditsafeId: string;
  safeNumber: string;
  name: string;
  type: string;
  status: string;
  registrationNumber: string;
  address: string;
  postalCode: string;
  createdAt: string;
  updatedAt: string;
}

interface IClientData {
  allMonitorClients: {
    data: IClient[];
    state: "loading" | "error" | "success";
  };
}

interface IDeepClientViewState {
  deepClientView: {
    data: IMonitorLink | null;
    state: "loading" | "error" | "success" | "uninitialized";
  };
}

interface State {
  clientData: IClientData;
  deepClientView: IDeepClientViewState;
}

interface Actions {
  updateAllMonitorClients: (
    clients: IClient[],
    state: "loading" | "error" | "success"
  ) => void;
  setDeepClientView: (
    client: IMonitorLink | null,
    state: "loading" | "error" | "success" | "uninitialized"
  ) => void;
  getActiveMonitorLink: () => IMonitorLink | null; // New action to return state
}

const INITIAL_STATE: State = {
  clientData: {
    allMonitorClients: { data: [], state: "loading" },
  },
  deepClientView: {
    deepClientView: { data: null, state: "uninitialized" },
  },
};

export const useClientStore = create<State & Actions>((set: any, get: any) => ({
  ...INITIAL_STATE,

  updateAllMonitorClients: (clients, state) => {
    set({
      clientData: {
        allMonitorClients: {
          data: clients,
          state: state,
        },
      },
    });
  },

  setDeepClientView: (client, state) => {
    set({
      deepClientView: {
        deepClientView: {
          data: client,
          state: state,
        },
      },
    });
  },

  getActiveMonitorLink: () => {
    const { deepClientView } = get().deepClientView;
    return deepClientView.state === "success" &&
      deepClientView.data
      ? deepClientView.data
      : null;
  },
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("ClientStore", useClientStore);
}
