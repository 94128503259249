import authAxiosInstance from "../lib/interceptors/apiInterceptor";

class MonitorService {
  async setFavoriteClient(
    monitorId: string,
    organizationId: string
  ): Promise<any> {
    try {
      let apiUrl = `/monitor/${organizationId}/${monitorId}/favorite`;

      const response = await authAxiosInstance.post(apiUrl);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createMonitorLink(data: any): Promise<any> {
    try {
      let apiUrl = `/monitor`;

      const response = await authAxiosInstance.post(apiUrl, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteMonitorLink(
    monitorId: number,
    organizationId: string
  ): Promise<any> {
    try {
      let apiUrl = `/monitor/${organizationId}/${monitorId}`;

      const response = await authAxiosInstance.delete(apiUrl);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getMonitorClient(
    monitorId: number,
    organizationId: string
  ): Promise<any> {
    try {
      let apiUrl = `/monitor/${organizationId}/${monitorId}`;

      const response = await authAxiosInstance.get(apiUrl);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateMonitorAssignees(
    monitorId: number,
    organizationId: string,
    data: any
  ): Promise<any> {
    try {
      let apiUrl = `/monitor/${organizationId}/${monitorId}/assignees`;

      const response = await authAxiosInstance.put(apiUrl, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new MonitorService();
